<template>
    <!--컨텐츠 시작-->
    <div class="content_container seller_register">
        <div v-if="isUpdate" class="top_title">
            <h1>매칭 수정</h1>
            <p>등록하신 매칭을 수정합니다. ( <span>*</span> 필수입력 )</p>
        </div>
        <div v-else class="top_title">
            <h1>매칭 등록</h1>
            <p>어떤 상품을 찾고 계신가요? ( <span>*</span> 필수입력 )</p>
        </div>

        <div class="fill">
            <!--상품 정보 시작-->
            <div class="fill_product_wrap" id="productInfo_box">
                <p class="fill_box_title">매칭 상품 입력<span class="star">*</span></p>
                <div class="fill_text">
                    <h1>매칭상품명 <span class="star">*</span></h1>
                    <input type="text" placeholder="상품명을 입력해주세요." v-model="name" id="name" />
                </div>

                <div class="fill_explain">
                    <h1>매칭상품 설명 <span class="star">*</span></h1>
                    <textarea
                        placeholder="상품 정보를 자세히 작성해주세요.&#10;회사명, 연락처 정보 입력 시 서비스 이용이 제한됩니다."
                        v-model="explanation"
                        id="explanation"
                    ></textarea>
                </div>

                <p class="fill_box_title">매칭상품 이미지<strong>(각 이미지당 최대 2.5MB)</strong></p>
                <div class="fill_image">
                    <div class="product_image" v-for="i in 5" :key="i">
                        <img
                            v-if="!this.imageThumb[i - 1]"
                            src="@/assets/images/register/icon_image_add.svg"
                            class="add_image"
                            alt="더하기"
                            @click="$refs.imageInput.click()"
                        />
                        <img v-if="this.imageThumb[i - 1]" :src="imageThumb[i - 1]" alt="imageThumb" />
                        <span v-if="this.imageThumb[i - 1]" @click="deleteProductImage(i - 1)">
                            <img src="@/assets/images/register/icon_image_remove.svg" alt="빼기" />
                        </span>
                    </div>
                    <input
                        type="file"
                        style="display: none"
                        multiple
                        @change="onImageSelected($event)"
                        accept="image/*"
                        capture="camera"
                        ref="imageInput"
                        @click="() => { this.$refs.imageInput.value = '' }"
                    />
                </div>
                <p class="fill_image_info">- 찾고계신 상품의 이미지나 유사한 이미지를 올려주세요.</p>
            </div>
            <!--상품 정보 시작-->

            <!--판매 정보 시작-->
            <div class="fill_select_wrap" id="saleInfo_box">
                <h1 class="fill_box_title">매칭 정보 입력 <span class="star">*</span></h1>
                <div class="fill_select">
                    <h1>요청 수량 <span class="star">*</span></h1>
                    <select v-model="moq" id="moq">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.moq" :key="i" :value="item.moq_pk">
                            {{ item.content }}
                        </option>
                    </select>
                </div>

                <div class="fill_select">
                    <h1>카테고리 <span class="star">*</span></h1>
                    <select v-model="category" id="category">
                        <option value="">선택</option>
                        <option
                            v-for="(item, i) in this.basicData.category.filter((el) => el.level == 1)"
                            :key="i"
                            :value="item.category_pk"
                        >
                            {{ item.content }}
                        </option>
                    </select>
                </div>

                <div class="fill_select">
                    <h1>상품 단가 <span class="star">*</span></h1>
                    <select v-model="price" id="price">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.price" :key="i" :value="item.price_pk">
                            {{ item.content }}
                        </option>
                    </select>
                </div>

                <div class="fill_select">
                    <h1>예상 마진 <span class="star">*</span></h1>
                    <select v-model="margin" id="margin">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.margin" :key="i" :value="item.margin_pk">
                            {{ item.content }}
                        </option>
                    </select>
                </div>

                <div class="fill_select">
                    <h1>판매 가능 시점 <span class="star">*</span></h1>
                    <select v-model="start" id="start">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.start" :key="i" :value="item.start_pk">
                            {{ item.sale_content }}
                        </option>
                    </select>
                </div>
                <div class="fill_select">
                    <h1>선호 공급자 경력<span class="star">*</span></h1>
                    <select v-model="career" id="career">
                        <option value="">선택</option>
                        <option v-for="(item, i) in this.basicData.career" :key="i" :value="item.career_pk">
                            {{ item.supply_user_content }}
                        </option>
                    </select>
                </div>
            </div>
            <!--판매 정보 끝-->

            <!-- 선호 상품 조건 시작 -->
            <div class="fill_way fill_check" id="condi_box">
                <h1 class="fill_box_title">선호 상품 조건<span class="star">*</span><strong>(최대 5개)</strong></h1>
                <div class="type">
                    <label v-for="(item, i) in this.basicData.condi" :key="i">
                        <input type="checkbox" name="condi" v-model="condi" :value="item.condi_pk" :id="`condi${i}`" />
                        {{ item.matching_content }} <span></span>
                    </label>
                </div>
            </div>
            <!-- 선호 상품 조건 끝 -->

            <!--URL 시작-->
            <div class="fill_last_wrap" v-if="isUpdate">
                <h1 class="fill_box_title fill_url">참고 URL</h1>
                <div class="fill_text">
                    <h1>URL 주소</h1>
                    <input
                        type="text"
                        placeholder="(선택) 비슷한 상품의 판매 주소(URL)를 입력해주세요."
                        id="url"
                        v-model="url"
                    />
                </div>
                <br />
                <h1 class="fill_box_title">모집 마감일</h1>
                <div class="fill_date">
                    <h1>날짜</h1>
                    <input
                        type="date"
                        data-placeholder="YYYY-MM-DD"
                        required
                        area-required="true"
                        v-model="finish_date"
                        id="finish_date"
                    />
                </div>
                <br />
                <h1 class="fill_box_title">매칭 상태 변경</h1>
                <div class="switch_toggle">
                    <input id="state" type="checkbox" v-model="updateData.state" />
                    <label for="state"></label>
                    <p>{{ updateData.state ? "매칭중" : "비매칭중" }}</p>
                </div>
            </div>
            <div v-if="!isUpdate" class="fill_double">
                <div>
                    <h1 class="fill_box_title fill_url">참고 URL</h1>
                    <div class="fill_text">
                        <h1>URL 주소</h1>
                        <input
                            type="text"
                            placeholder="(선택) 비슷한 상품의 판매 주소(URL)를 입력해주세요."
                            id="url"
                            v-model="url"
                        />
                    </div>
                </div>
                <div>
                    <h1 class="fill_box_title">모집 마감일</h1>
                    <div class="fill_date">
                        <h1>날짜</h1>
                        <input
                            type="date"
                            data-placeholder="YYYY-MM-DD"
                            required
                            area-required="true"
                            v-model="finish_date"
                            id="finish_date"
                        />
                    </div>  
                </div>
            </div>
            <button v-if="!isUpdate" type="submit" class="common_button submit_button" @click="submit">등록하기</button>
        </div>
        <div v-if="isUpdate" class="btn_wrap">
            <button v-if="isUpdate" type="submit" class="sub_button submit_button" @click="deleteMatching">
                매칭 삭제
            </button>
            <button v-if="isUpdate" type="submit" class="common_button submit_button" @click="submit">수정하기</button>
        </div>
    </div>
    <!--컨텐츠 끝-->
</template>

<script>
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { mapState } from "vuex";

export default {
    beforeRouteLeave(to, from, next) {
        if (!this.isFormEmpty() && !this.flag) {
            this.$store.commit("confirm", {
                content: "페이지를 떠나면 입력하신 정보가 사라집니다. 페이지를 떠나시겠습니까?",
                callback: () => {
                    this.isUpdate = false;
                    this.updateData = {};
                    this.$store.commit("setMatchingData", {});
                    this.$store.commit("setUpdate", false);
                    this.clearForm();
                    next();
                },
            });
        } else {
            this.isUpdate = false;
            this.updateData = {};
            this.$store.commit("setMatchingData", {});
            this.$store.commit("setUpdate", false);
            this.clearForm();
            next();
        }
    },
    mixins: [fetchData, postData],
    data() {
        return {
            flag: false, // 등록 여부
            // 보낼 데이터
            name: "",
            explanation: "",
            image: [],
            imageThumb: [],
            moq: "",
            category: "",
            price: "",
            margin: "",
            start: "",
            condi: [],
            career: "",
            finish_date: "",
            url: "",

            // 받을 데이터
            basicData: {
                category: [],
                channel: [],
                margin: [],
                price: [],
                start: [],
                condi: [],
                career: [],
            },

            //수정 or 등록
            isUpdate: false,
            updateData: {},
            deletedImages: [],
        };
    },
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("access_token", ["access_token"]),
    },
    watch: {
        condi: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.length > 5) {
                    this.condi = oldVal;
                }
            },
        },
        $route(to) {
            if (to.path === "/register/seller") {
                this.clearForm();
            }
        },
    },
    created() {
        if (this.$route.path.includes("/update/seller")) {
            if (this.$store.state.isUpdate === false) {
                this.$store.commit("alert", "잘 못된 접근입니다.");
                this.$router.push("/");
            }
            this.updateData = this.$store.state.matchingData;
            this.isUpdate = true;
        }
        this.fetchBasicData();

        if (this.isUpdate) {
            this.name = this.updateData.name;
            this.explanation = this.updateData.explanation;
            this.imageThumb = this.updateData.matching_image.map((item) => item.path + item.save_name);
            this.category = this.updateData.category;
            this.margin = this.updateData.margin;
            this.moq = this.updateData.moq;
            this.price = this.updateData.price;
            this.start = this.updateData.start;
            this.finish_date = this.updateData.finish_date ? this.updateData.finish_date.slice(0, 10) : "";
            this.career = this.updateData.career;
            this.url = this.updateData.url;
            this.condi = this.updateData.matching_condi.map((item) => item.condi_pk);
        }
    },
    mounted() {},
    methods: {
        // 기본 목록 데이터 불러오기
        fetchBasicData() {
            this.fetchData("/getBasic?page=r_seller", (data) => {
                this.basicData = data;
            });
        },
        // 상품 이미지 추가
        onImageSelected(event) {
            // 파일이 존재하는지
            if (event.target.files && event.target.files[0]) {
                if (event.target.files.length + this.image.length > 5) {
                    this.$store.commit("alert", "최대 5개를 선택해주세요");
                    return;
                }
                for (let i = 0; i < event.target.files.length; i++) {
                    // 2MB보다 크면 안받도록
                    if (event.target.files[i].size > 1048576 * 2.5) {
                        this.$store.commit("alert", "2.5MB 이하 이미지를 선택해 주세요");
                    }
                    // 이미지일 때만 this.image에 넣음
                    else if (
                        !event.target.files[i].type.includes("image") ||
                        event.target.files[i].type.includes("gif")
                    ) {
                        this.$store.commit("alert", "이미지만 선택해주세요. (.gif 제외)");
                    } else {
                        this.image.push(event.target.files[i]);

                        const reader = new FileReader();
                        reader.onload = (event) => {
                            this.imageThumb.push(event.target.result);
                        };
                        reader.readAsDataURL(event.target.files[i]);
                    }
                }
            }
        },
        // 상품 이미지 삭제
        deleteProductImage(i) {
            // isUpdate인 경우 기존에 등록되어있던(서버에 저장되어있는) 이미지면 deletedImages에 추가 후 imageThumb에서 삭제
            if (this.isUpdate) {
                if (this.updateData.product_image.find((item) => item.path + item.save_name === this.imageThumb[i])) {
                    const image = this.updateData.product_image.filter(
                        (item) => item.path + item.save_name === this.imageThumb[i],
                    )[0];
                    this.deletedImages.push(image.product_image_pk);
                    this.imageThumb.splice(i, 1);
                } else {
                    this.image.splice(i, 1);
                    this.imageThumb.splice(i, 1);
                }
            } else {
                this.image.splice(i, 1);
                this.imageThumb.splice(i, 1);
            }
        },

        submit() {
            // 필수 입력 체크
            if (!this.name) {
                this.$store.commit("alert", "상품명을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("productInfo_box"));
                document.getElementById("name").focus();
            } else if (!this.explanation) {
                this.$store.commit("alert", "상품 설명을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("productInfo_box"));
                document.getElementById("explanation").focus();
            } else if (!this.moq) {
                this.$store.commit("alert", "요청 수량을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("saleInfo_box"));
                document.getElementById("moq").focus();
            } else if (!this.category) {
                this.$store.commit("alert", "카테고리를 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("saleInfo_box"));
                document.getElementById("category").focus();
            } else if (!this.price) {
                this.$store.commit("alert", "상품 단가를 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("saleInfo_box"));
                document.getElementById("price").focus();
            } else if (!this.margin) {
                this.$store.commit("alert", "예상 마진을 선택해주세요.");
                this.toggleRequiredBox(document.getElementById("saleInfo_box"));
                document.getElementById("margin").focus();
            } else if (!this.start) {
                this.$store.commit("alert", "판매 가능 시점을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("saleInfo_box"));
                document.getElementById("start").focus();
            } else if (!this.career) {
                this.$store.commit("alert", "선호 공급자 경력을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("saleInfo_box"));
                document.getElementById("career").focus();
            } else if (this.condi.length == 0) {
                this.$store.commit("alert", "선호 상품 조건을 입력해주세요.");
                this.toggleRequiredBox(document.getElementById("condi_box"));
                document.getElementById("condi0").focus();
            }
            // 문제 없으면 보내기
            else {
                this.toggleRequiredBox(null);

                const formData = new FormData();
                if (this.isUpdate) {
                    formData.append("matching_pk", this.updateData.matching_pk);
                    formData.append("state", this.updateData.state);
                    formData.append("del_image_pk", this.deletedImages);
                }
                formData.append("access_token", this.access_token); // 유저 토큰
                formData.append("name", this.name); // 상품명 (*)
                formData.append("explanation", this.explanation); // 상품 설명 (*)
                for (let i = 0; i < this.image.length; i++) {
                    formData.append(`image${i}`, this.image[i]); // 상품 이미지
                }
                formData.append("imageCount", this.image.length); // 이미지 개수
                formData.append("moq", this.moq); // 요청 수량 (*)
                formData.append("category", this.category); // 카테고리 (*)
                formData.append("price", this.price); // 상품 단가 (*)
                formData.append("margin", this.margin); // 예상 마진 (*)
                formData.append("start", this.start); // 판매 가능 시점 (*)
                formData.append("condi", this.condi); // 선호 상품 조건 (*)
                formData.append("career", this.career); // 선호 공급자 경력 (*)
                formData.append("url", this.url); // 참고 url
                formData.append("finish_date", this.finish_date); // 모집 마감일

                this.postData("/saveMatching", formData, () => {
                    this.flag = true;
                    if (this.isUpdate) {
                        this.$router.push("/mypage/ManageMatching");
                        this.$store.commit("alert", "매칭이 수정되었습니다.");
                    } else {
                        this.$store.commit("alert", "매칭이 등록되었습니다.");
                        this.$router.push("/");
                    }
                });
            }
        },
        toggleRequiredBox(element) {
            const productInfoBox = document.getElementById("productInfo_box");
            const saleInfo = document.getElementById("saleInfo_box");

            let requiredBoxes = [productInfoBox, saleInfo];
            requiredBoxes.forEach((el) => el.classList.remove("required_box"));

            if (element != null) {
                element.classList.add("required_box");
            }
        },
        isFormEmpty() {
            if (
                !this.name &&
                !this.explanation &&
                !this.moq &&
                !this.category &&
                !this.price &&
                !this.margin &&
                !this.start &&
                !this.career &&
                this.image.length === 0 &&
                this.condi.length == 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        deleteMatching() {
            this.$store.commit("confirm", {
                content: "매칭을 삭제하시겠습니까?",
                callback: () => {
                    this.postData(
                        "/deleteMyMatching",
                        { matching_pk: this.updateData.matching_pk },
                        () => {
                            this.flag = true;
                            this.$store.commit("alert", "매칭이 삭제되었습니다.");
                            this.$router.push("/mypage/ManageMatching");
                        },
                    );
                },
            });
        },
        clearForm() {
            this.name = "";
            this.explanation = "";
            this.image = [];
            this.imageThumb = [];
            this.moq = "";
            this.category = "";
            this.price = "";
            this.margin = "";
            this.start = "";
            this.condi = [];
            this.career = "";
            this.finish_date = "";
            this.url = "";
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_registerSeller.css"></style>
<style scoped>
.btn_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn_wrap button.common_button {
    border: none;
    background-color: var(--point-color);
    box-sizing: border-box;
    color: #fff;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: var(--font-small);
    width: 178px;
    height: 50px;
    margin: 1rem;
    margin-top: 3rem;
}
.btn_wrap button.common_button:hover {
    background-color: var(--point-color-hover);
}
.sub_button {
    border: none;
    background-color: var(--primary-color1);
    box-sizing: border-box;
    color: #fff;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: var(--font-small);
    width: 178px;
    height: 50px;
    margin: 1rem;
    margin-top: 3rem;
}

.sub_button:hover {
    /* border-color: var(--point-color-hover);
  color: var(--point-color-hover); */
    background-color: var(--primary-color2);
}
</style>
